import { Vue, Component, Ref } from "vue-property-decorator";
import { telegramSupportBotLink, supportEmail } from "@config/social";
import { scrollToEl } from "@helpers";
import { profileModule } from "@store/namespaces";
import { ProfileGetters } from "@store/modules/profile/types";

@Component({
  components: {
    HomeLayout: () => import("@/layouts/HomeLayout/HomeLayout.vue"),
    Footer: () => import("@/layouts/HomeLayout/components/Footer/Footer.vue"),
  },
})
export default class Home extends Vue {
  @Ref("contentList") private readonly contentListRef!: HTMLDivElement;
  @profileModule.Getter("profileLanguage")
  private readonly profileLanguageGetter!: ProfileGetters["profileLanguage"];

  private get profileLanguageCode() {
    return this.profileLanguageGetter(this.$vuetify.lang.current);
  }

  private get telegramSupportBotLink() {
    return telegramSupportBotLink;
  }

  private get supportEmail() {
    return supportEmail;
  }

  private scrollToEl(id: string) {
    scrollToEl(id);
  }
}
